import React, { Component } from 'react';

// Import Utils
// import isMobile from '../../../utils/isMobile';

// Import components
import Brand from '../../Brand/Brand';

// Import SCSS
import './About.scss';

class About extends Component {
    state = {
        // logos: isMobile() ? this.props.data.brands.logos.filter((current, index) => index < this.props.closedCount) : [...this.props.data.brands.logos],
        logos: [...this.props.data.brands.logos],
        allLogos: [...this.props.data.brands.logos],
        open: false
    }

    // componentDidMount() {
    //     // Handle resize event to retest viewport
    //     window.addEventListener("resize", this.resizeHandler.bind(this));
    // }

    // componentWillUnmount() {
    //     // Remove resize event
    //     window.removeEventListener("resize", this.resizeHandler)
    // }

    // // Handle resize
    // resizeHandler = () => {
    //     // Check if it is a mobile viewport...
    //     // If it's mobile, and it's not open, show only 6
    //     let newLogos = (isMobile() && !this.state.open) ? this.state.allLogos.filter((current, index) => index < this.props.closedCount) : [...this.state.allLogos];

    //     this.setState({logos: newLogos});
    // }

    // Handle open
    openHandler = () => {
        // Get all logos
        let newLogos = [...this.state.allLogos];

        this.setState({ logos: newLogos, open: true });
    }

    render() {
        // const openBtn = isMobile() && !this.state.open ? <button className="about--brands-open" onClick={this.openHandler}>{this.props.data.brands.open}</button> : null;
        const openBtn = null;

        return (
            <section id="about" className="selector" data-selector="about">
                <h3 className="about--headline about--headline__about">{this.props.data.headline}</h3>
                <h2 className="about--title">{this.props.data.title}</h2>
                <p className="about--description">{this.props.data.description}</p>
                <ul className="about--informations">
                    { this.props.data.information.map((infoData, index) => (<li key={index}><span>{infoData.value}</span> {infoData.text}</li>)) }
                </ul>
                <h3 className="about--headline about--headline__brands">{this.props.data.brands.headline}</h3>
                <ul className="about--brands">
                    {this.state.logos.map((logoData, index) => (<li key={index}><Brand slug={logoData.slug} name={logoData.name} /></li>))}
                </ul>
                {openBtn}
            </section>
        );
    }
}

export default About;
