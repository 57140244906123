import React from 'react';

import fb_ico from '../../images/facebook.png';
import li_ico from '../../images/linkedin.png';
import ig_ico from '../../images/instagram.png';

// import { NavLink } from 'react-router-dom';

import './Footer.scss';

const footer = (props) => {
    return (
        <footer id="footer">
            {/* <NavLink className="footer_privacy-politics" to="/politica-de-privacidade">Política de Privacidade</NavLink> */}
            <a href="/downloads/priv-dan.pdf" download className="footer_privacy-politics">Política de Privacidade</a>
            <a href="/downloads/faq-dan.pdf" download className="footer_faq">Perguntas frequentes</a>
            <div className="social-media_wrapper">
                <div className="social-media_icon facebook-media">
                    <a href="https://www.facebook.com/dentsuBR" target="blank">
                        <img className="facebook-icon" src={fb_ico} alt="Facebook icon"/>
                        <span>Facebook</span>
                    </a>
                </div>
                <div className="social-media_icon linkedin-media">
                    <a href="https://www.linkedin.com/company/dentsuintl/" target="blank">
                        <img className="linkedin-icon" src={li_ico} alt="LinkedIn icon"/>
                        <span>Linkedin</span>
                    </a>
                </div>
                <div className="social-media_icon instagram-media">
                    <a href="https://www.instagram.com/dentsubr/" target="blank">
                        <img className="instagram-icon" src={ig_ico} alt="Instagram icon"/>
                        <span>Instagram</span>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default footer;
