import './Notification.scss';

import React, { Component } from 'react';
import cls from 'classnames';
import cookie from 'react-cookies';

class Notification extends Component {

	constructor(props){
		super(props);

		this.state = {}
	}

	handleClose = (error) => {
		if(!error) cookie.save("agreed", true)
		this.props.handle(null)
	}

	componentDidMount() {
		document.body.style.overflow = 'hidden';
	}

	componentWillUnmount() {
		document.body.removeAttribute('style');
	}

	render() {
		let { title, agree } = this.props 
		title = title || "Notificação"
		agree = agree || "Ok, Fechar"

		return (
			<div id="notification" className="notification">
				<div className="notification_bg"/>
				<div className={cls("notification_info-wrap", { error: this.props.error })}>
					<div className="notification_title">{title}</div>
					<div className="notification_info-text" dangerouslySetInnerHTML={{__html: this.props.info}}></div>
					<button className="notification_agree" onClick={() => this.handleClose(this.props.error)}>{agree}</button>
				</div>
			</div>
		);
	}
}

export default Notification;
