import React, { Component } from 'react';

import cls from "classnames";
import cookies from 'react-cookies'

// Import content
import HomeData from '../../data/Home';

// Import Utils
import isMobile from '../../utils/isMobile';

// Import partials
import Hero from '../../components/Home/Hero/Hero';
import About from '../../components/Home/About/About';
import How from '../../components/Home/How/How';
// import Preform from '../../components/Home/Preform/Preform';
import Experience from '../../components/Home/Experience/Experience';
import Form from '../../components/Home/Form/Form';
import Feedback from '../../components/Home/Feedback/Feedback';
import Notification from '../../components/Notification/Notification';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import BackStep from '../../components/Navigation/BackStep/BackStep';

// import Corona from '../../components/Home/Corona/Corona';

class Home extends Component {

    state = {
        step: this.props.match.path === '/politica-de-privacidade' ? 3 : 0,
        active: null,
        formReady: false,
        form: null,
        notification: null,
    }

    goto = (step) => {
        this.setState({step: step});
        window.scrollTo(0,1)
        window.scrollTo(0,0)

        if(!cookies.load("agreed")) this.handleNotification(HomeData.notification.cookie)
    }

    handleNotification = (notification) => {
        this.setState({ notification })
    }

    handleFormChange = (event, id) => {
        const newValue = event.currentTarget.value;

        this.setState({
            form: {level: newValue},
            formReady: true
        })
    }

    startForm = (event) => {
        event.preventDefault();
        this.goto(1);
    }

    sectionOnView = () => {
        let Sections = Array.from(document.querySelectorAll('section'))

        Sections.forEach((section, i) => {
            let rect = section.getBoundingClientRect()
            let nav = isMobile() ? document.querySelector('nav').clientHeight : window.innerHeight*0.2

            let {selector} = section.dataset,
            top = (rect.top - nav),
            bottom = (rect.bottom - nav)

            if(top <= 1 && bottom > 1 && this.state.active !== selector) {
                this.setState({ active: selector })
            }
        })
    }

    componentDidUpdate(props, state){
        let step = this.props.match.path === '/politica-de-privacidade' ? 3 : (this.state.step === 3 ? 0 : this.state.step)

        if(this.state.step !== step) {
            this.goto(step)
            return true
        }

        return
    }

    componentDidMount() {
        if(!cookies.load("agreed") && this.state.step !== 3) this.handleNotification(HomeData.notification.cookie)

        this.sectionOnView()

        window.addEventListener('scroll', this.sectionOnView)
    }

    render() {
        let experienceBtn = null;
        // let preformBtn = null;

        experienceBtn = <a href={HomeData.hero.register.anchor} style={{ cursor: "pointer", textDecoration: "none" }} className="experience--form-btn">{HomeData.preform.start}</a>;

        // if (this.state.formReady) {
        //     preformBtn = <button className="preform--form-btn" onClick={this.startForm}>{HomeData.preform.start}</button>;
        // }

        const steps = [
            (
                <React.Fragment>
                    {/* <Corona isMobile={this.props.isMobile}
                        data={HomeData.corona}
                    /> */}
                    <Hero isMobile={this.props.isMobile}
                        data={HomeData.hero}
                    />
                    <About isMobile={this.props.isMobile}
                        closedCount="6"
                        data={HomeData.about}
                    />
                    <How isMobile={this.props.isMobile}
                        data={HomeData.how}
                    />
                    <Experience isMobile={this.props.isMobile}
                        data={HomeData.preform}
                        experienceBtn={experienceBtn}
                        change={this.handleFormChange}
                        form={this.state.form}
                    />
                </React.Fragment >
            ),
            (
                <React.Fragment>
                    <BackStep goBack={this.goto}/>
                    <Form isMobile={this.props.isMobile}
                        goto={this.goto}
                        // handleNotification={this.handleNotification}
                        preform={this.state.form}
                        data={HomeData.form}
                    />
                </React.Fragment>
            ),
            (
                <React.Fragment>
                    <BackStep goBack={this.goto}/>
                    <Feedback isMobile={this.props.isMobile}
                        data={HomeData.preform}/>
                </React.Fragment>

            ),
            (
                <React.Fragment>
                    <BackStep goBack={this.goto}/>
                    <PrivacyPolicy />
                </React.Fragment>
            )
        ]

        const Step = () => steps[this.state.step]
        let activeclass = {}
        activeclass[`section_${this.state.active}`] = this.state.active

        let { notification } = this.state

        return (
            <main className={cls(activeclass)}>
                <Step />
                {notification && <Notification {...notification} handle={this.handleNotification}/>}
            </main>
        );
    }
}

export default Home;
