import React from 'react';

// Import Utils
import isMobile from '../../../utils/isMobile';

// Import SCSS
import './How.scss';


const How = (props) => {
    return (
        <section id="how" className="selector" data-selector="how">
            <h3 className="how--headline">{props.data.headline}</h3>
            <h2 className="how--title">{props.data.title}</h2>
            {props.data.description.map((descData, index) => (<p className={"how--description desc_" + index} key={index}>{descData}</p>))}
            <ul className="how--informations">
                {props.data.information.map((infoData, index) => (<li key={index}  dangerouslySetInnerHTML={{__html: infoData}}></li>))}
            </ul>

            { !isMobile() ? <span className="how--separator"/> : null }
        </section>
    );
}

export default How;
