import React, { Component } from 'react';

class Dnd extends Component {
    state = {
        dropHere: this.props.dropHere ? this.props.dropHere : 'Drop Here',
        loadText: this.props.loadText ? this.props.loadText : 'Uploading...',
        dragging: false,
        loading: false
    }

    dropRef = React.createRef();

    handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    handleDragIn = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.dragCounter++;
        this.setState({ dragging: true });
    }

    handleDragOut = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter > 0) return;
        this.setState({ dragging: false });
    }

    handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ drag: false });
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            this.dragCounter = 0;
            this.setState({ dragging: false, loading: true });
            this.props.handleFiles(event.dataTransfer.files);
        }
    }

    documentDragPreventRedirect = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    documentDropPreventRedirect = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter > 0) return;
        this.setState({ dragging: false });
    }

    handleClick = (event) => {
        const div = this.dropRef.current;

        div.querySelector('input').click();
    }

    handleInputChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            this.setState({ loading: true });
            this.props.handleFiles(event.target.files);
        }
    }

    componentDidMount() {
        const div = this.dropRef.current;

        this.dragCounter=0;

        window.addEventListener('dragover', this.documentDragPreventRedirect);
        window.addEventListener('drop', this.documentDropPreventRedirect);
        document.addEventListener('dragenter', this.handleDragIn);
        document.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);
        div.addEventListener('click', this.handleClick);
        div.querySelector('input').addEventListener('change', this.handleInputChange);
    }

    componentDidUnount() {
        const div = this.dropRef.current;

        window.removeEventListener('dragover', this.documentDragPreventRedirect);
        window.removeEventListener('drop', this.documentDropPreventRedirect);
        document.removeEventListener('dragenter', this.handleDragIn);
        document.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
        div.removeEventListener('click', this.handleClick);
        div.querySelector('input').removeEventListener('change', this.handleInputChange);
    }

    render() {
        return (
            <div ref={this.dropRef} className={this.props.className}>
                {this.state.dragging &&
                    <div className="dnd-overlay"><span>{this.state.dropHere}</span></div>
                }
                {this.state.loading &&
                    <div className="dnd-loading"><span>{this.state.loadText}</span></div>
                }
                <input type="file" style={{display: 'none'}} />
                {this.props.children}
            </div>
        )
    }
}

export default Dnd;
