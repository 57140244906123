import React from 'react';

// Import SCSS
import './Experience.scss';

const Experience = (props) => {
    return (
        <section id="experience" className="selector" data-selector="experience">
            <h2 className="experience--title">{props.data.title}</h2>
            {/* <p className="experience--text">{props.data.text}</p> */}
            {/* {props.experienceBtn} */}
            {props.data.inputs.map((inputData, index) => {
                let buttons;
                if (inputData.anchors) {
                    buttons = inputData.anchors.map((btnData, btnIndex) => (<a key={`btn-${btnIndex}`} href={btnData.href} style={{ cursor: "pointer", textDecoration: "none" }} className={`experience--form-btn experience--form-btn__double experience--form-btn__${index + btnIndex}`}>{btnData.label}</a>));
                } else {
                    buttons = (<a href={inputData.anchor} style={{ cursor: "pointer", textDecoration: "none" }} className={`experience--form-btn experience--form-btn__${index}`}>{props.data.start}</a>);
                }
                return (
                    <React.Fragment key={`experience-${index}`}>
                        <p className="experience--text">{inputData.text}<span>{inputData.subtext}</span></p>
                        {buttons}
                    </React.Fragment>
                )}
            )}
        </section>
    );
}

export default Experience;
