import isMobile from './isMobile';

function scrollTo(to, duration, sequence) {
    duration = duration || 300;
    
    let nav = isMobile() ? document.querySelector('nav').clientHeight : 0
    let limit = document.body.scrollHeight - window.innerHeight;
    
    if(!sequence) to = to - nav
    if(to > limit ) to = limit
    
    let difference = to - window.scrollY;
    let perTick = difference / duration * 10;
    let result = window.scrollY + perTick

    window.scroll(0, result);
    if (window.scrollY === to) return;

    window.requestAnimationFrame(() => scrollTo(to, duration - 10, true));
}

export default scrollTo

