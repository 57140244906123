const data = {
	
	title: "Cadastro feito com sucesso!",
	subtitle: "Em breve entraremos em contato com você por email.",
	share: "Compartilhe:",
	links: [
		{
			name: "Facebook",
			slug: "facebook",
			img: "../../../images/success_fb.png",
			link: `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}`,
			type: "social"
		},
		{
			name: "Twitter",
			slug: "twitter",
			img: "../../../images/success_tt.png",
			link: `https://twitter.com/home?status=${window.location.origin}`,
			type: "social"
		},
		{
			name: "LinkedIn",
			slug: "linkedIn",
			img: "../../../images/success_li.png",
			link: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.origin}&title=&summary=&source=`,
			type: "social"
		},
		{
			name: "Copy",
			slug: "copy",
			img: "../../../images/success_copy.png",
			link: window.location.origin,
			type: "copy"
		}
	]
}

export default data;